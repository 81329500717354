<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow width="600" height="180" :steps="workflowSteps" :currentStep="workflowIndex" :labelWidth="200"
        :lineWidth="140"></Workflow>
    </div>

    <v-row justify="center">
      <v-col cols="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="ACS > rôles des fonctions" />
        </div>

        <!-- la progess bar à afficher lors du chargement des données -->
        <v-progress-linear indeterminate :active="loading || running"></v-progress-linear>

        <v-card flat outlined class="mx-auto">
          <v-card-title class="font-weight-regular">
            <v-row no-gutters>
              <!-- Titre de la table -->
              <div>Consulter les rôles associés aux fonctions</div>

              <v-spacer></v-spacer>

              <!-- Bouton de modification -->
              <v-btn v-if="canEditRole && !modeEdition" icon color="primary" @click="clickOnModeEdition">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-row justify="end" no-gutters v-if="modeEdition">
                <v-btn class="btn" color="primary" text @click="clickOnCancelEdit" :disabled="loading">
                  Quitter l'édition
                </v-btn>

                <v-btn icon color="primary" :disabled="!hasChanged || loading" @click="save">
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
                <!-- <v-btn
                outlined
                class="btn ml-4"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
                >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn> -->
              </v-row>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table :headers="availableHeaders" :items="availableItems" item-key="label" :search="search"
              :custom-filter="filterOnlyCapsText" disable-pagination hide-default-footer sort-by="name">
              <!-- Template d'analyse des items en fonction des colonnes pour modifier l'affichage du contenu des cellules -->
              <template v-for="head in availableHeaders" v-slot:[`item.${head.value}`]="{ item }">
                <div :key="head.value">
                  <!-- Colonne "rôle" on affiche le role -->
                  <div v-if="head.value == 'name'">
                    {{ item[head.value] }}
                  </div>

                  <!-- Pour les autres colonnes, on traite l'affichage -->
                  <div v-else>
                    <v-row no-gutters justify="center">
                      <v-icon v-if="item[head.value] && item[head.value].hasRole && !modeEdition" small class="mr-2"
                        color="primary">
                        mdi-check
                      </v-icon>

                      <v-checkbox v-if="
                          modeEdition && item[head.value] &&
                          !item[head.value].isHerited &&
                          !item[head.value].isHeritedByService
                        " off-icon="mdi-checkbox-blank-outline" on-icon="mdi-checkbox-outline"
                        v-model="item[head.value].hasRole" color="primary" hide-details class="my-0"
                        @click="onClickCheckBox(item['name'], head.value)"></v-checkbox>

                      <!-- Tooltip conteant l'icône d'héritage -->
                      <v-tooltip bottom v-if="item[head.value] && item[head.value].isHerited">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small class="mr-2" color="secondary" v-bind="attrs" v-on="on">
                            mdi-account-switch-outline
                          </v-icon>
                        </template>
                        <span>Héritage par rôle supérieur</span>
                      </v-tooltip>

                      <!-- Tooltip conteant l'icône d'héritage -->
                      <v-tooltip bottom v-if="item[head.value] && item[head.value].isHeritedByService">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small class="mr-2" color="secondary" v-bind="attrs" v-on="on">
                            mdi-account-supervisor
                          </v-icon>
                        </template>
                        <span>Héritage par service parent</span>
                      </v-tooltip>
                    </v-row>
                  </div>
                </div>
              </template>

              <template v-slot:top>
                <v-row class="mb-4">
                  <v-checkbox class="mx-2" on-icon="mdi-checkbox-outline" off-icon="mdi-checkbox-blank-outline"
                    v-model="viewAllRole" label="Afficher les rôles non associés" />
                  <v-text-field v-model="search" label="Rechercher un rôle" class="mx-4"></v-text-field>
                </v-row>

                <v-row class="mb-4">
                  <v-autocomplete v-model="selectedService" :items="services" item-text="name" return-object
                    placeholder="Choisir un service" class="mx-4 my-0 pa-0" no-data-text="aucun service" clearable
                    @change="onSelectedServiceChange()">
                  </v-autocomplete>

                  <v-autocomplete v-model="selectedFunction" :items="availableFunction" item-text="name" return-object
                    placeholder="Choisir une fonction" class="mx-4 my-0 pa-0" no-data-text="aucune fonction" clearable
                    @change="onSelectedFunctionChange()" multiple>
                  </v-autocomplete>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions v-if="modeEdition">
            <v-spacer></v-spacer>
            <v-btn outlined class="ma-2 px-4 btn" color="primary" :disabled="!hasChanged || loading" @click="save">
              <div class="capitalize">enregistrer</div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <AlertNotSavedModifsComponent :show="showAlertQuit" @quit="onQuitAlert" @notquit="onNotQuitAlert" />

    <!-- afficher des messages -->
    <v-snackbar v-model="snackbarVisible" :color="snackbarColor" :timeout="snackbarTimeout" :left="snackbarLeft"
      :right="snackbarRight" :top="snackbarTop" :bottom="snackbarBottom">{{ snackbarMessage }}</v-snackbar>
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";
import * as exceptions from "@/service/exception_to_message.js";

import ServiceAcs from "@/service/acs/acs_service.js";
import AuthAdminService from "@/service/backend/auth_admin_service.js";

import * as logger from "@/tools/logger.js";

import { hasRoles } from "@/service/role_service.js";
import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "FonctionRolesAcs",

  components: {
    Workflow,
    TitleAndReturnComponent,
    AlertNotSavedModifsComponent,
  },
  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      // L'objet service métier
      serviceAcs: null,
      serviceAuthAdminBackend: null,

      search: "",

      /**chargement de la page en cours */
      loading: false,
      running: false,

      /**voir tous les rôles : même ceux non affectés à une fonction. */
      viewAllRole: false,

      /** Indique si l'utilisateur peut modifier les rôles */
      canEditRole: false,
      /** Indique que l'utilisateur est en mode d'édition des rôles */
      modeEdition: false,

      /**les entêtes de la table : rôle + fonctions  */
      headers: [],

      /**les rôles APM */
      rolesApm: [],
      rolesApmSource: [],

      /**Les lignes de la table */
      items: [],
      /** les items source de la table */
      itemsSource: [],

      // Le service sélectionné dans l'autocomplete dédié
      selectedService: null,
      // La fonction sélectionnée dans l'autocomplete dédié
      selectedFunction: [],

      /**les services */
      services: [],

      /**les fonctions */
      functions: [],

      /** Définition du rôle nécessaire pour l'édition de la vue */
      roleForEdition: [RolesApplicationEnum.EditAcs],

      /** Liste des lignes sur lesquelles il y a  des changements */
      changedRoles: [],
      /** Liste des colonnes sur lesquelles il y a  des changements */
      changedHeaders: []
    };
  },
  methods: {
    /** Méthode de chargement des datas */
    async load() {
      try {
        logger.debug("Chargement des données de la vue.");
        this.loading = true;

        // Récupération de la liste des rôles
        let allRoles = await this.serviceAuthAdminBackend.getRoles();

        // Récupération de la liste des fonctions
        let allFunctions =
          await this.serviceAuthAdminBackend.getRolesForFonctions();

        // Récupération de la liste des services
        let allServices =
          await this.serviceAuthAdminBackend.getRolesForServices();

        // logger.debug("Roles", allRoles[0]);
        // logger.debug("fonction", allFunctions[0]);
        // logger.debug("service", allServices[0]);

        // Construction de la liste des rôles (juste le nom)
        this.rolesApm.push(...allRoles.map((role) => role.name));
        this.rolesApmSource = [...allRoles];
        // Parcours chaque fonctions pour y ajouter l'héritage
        for (let func of allFunctions) {
          //on rajoute les informations d'héritage sur les rôles
          func.roles = this.serviceAcs.buildInheritance(
            allRoles,
            func.authorized
          );
        }

        // Affectation aux fonctions
        this.functions.push(...allFunctions);
        // Affectation aux services
        this.services.push(...allServices);

        // Construction des rows de la table
        this.items = this.buildItems();
        this.itemsSource = JSON.parse(JSON.stringify(this.items));
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Diff et sauvegarde des modifications */
    async save() {
      try {
        this.loading = true;

        logger.debug("SAVE");
        let functionChanged = this.findFonctionHasChanged();

        // Des fonctions ont changées
        if (functionChanged.length > 0) {
          // Parcours de la liste des fonctions qui ont changées
          for (let functionName of functionChanged) {
            let roles = [];

            // Parcours de chaque items (row)
            for (let item of this.items) {
              // La fonction en cours as-t-elle le rôle en cours
              if (item[functionName.toLowerCase()].hasRole) {
                // Construit la liste des rôles de la fonction
                roles.push(item.name);
              }
            }
            // Récupère la fonction dans la source
            let functionSource = this.functions.find(
              (f) => f.name == functionName
            );
            // Remplace le tableau de rôles par le nouveau
            functionSource.authorized = roles;
            // Suppression de la propriété "role"
            delete functionSource.roles;
            // Ajout à la liste des fonction a save
            await this.serviceAuthAdminBackend.updateRolesForFunction(
              functionSource
            );

            // Parcours de la liste des items source pour mise à jour
            for (let itemSrc of this.itemsSource) {
              // Récupération de l'item identique à la source en cours
              let item = this.items.find((i) => i.name == itemSrc.name);

              // Recopie pour le service en cours la valeur affichée
              itemSrc[functionName.toLowerCase()].hasRole =
                item[functionName.toLowerCase()].hasRole;
            }
          }
          this.modeEdition = false;
          this.changedRoles = [];
          this.changedHeaders = [];
        }
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "sauvegarde des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Trouve toutes les fonctions qui ont changées et les retourne en un tableau */
    findFonctionHasChanged() {
      let functionHasChanged = [];

      // Parcours de toutes les fonctions
      for (let func of this.functions) {
        // Met le nom de la fonction en minuscule
        let funcName = func.name.toLowerCase();

        // Parcours les rôles source
        for (let itemSrc of this.itemsSource) {
          // Récupère le clone du rôle en cours dans la liste affichée
          let item = this.items.find((i) => i.name == itemSrc.name);

          // Vérification de changement de check du service pour le rôle en cours
          if (item[funcName] && itemSrc[funcName] && item[funcName].hasRole != itemSrc[funcName].hasRole) {
            functionHasChanged.push(func.name);
            break;
          }
        }
      }
      return functionHasChanged;
    },

    /** Construction des items (rows) de la vue
     * Retourne le tableau créé
     */
    buildItems() {
      let items = [];

      // Parcours la liste des rôles
      for (let role of this.rolesApm) {
        let item = {};

        item.name = role;

        // Parcours de chaque fonctions (colonne)
        for (let func of this.functions) {
          // Objet d'infos pour un rôles / fonction
          item[func.name] = {};
          item[func.name].hasRole = false;
          item[func.name].isHerited = false;
          item[func.name].isHeritedByService = false;

          // Récupère le rôle en cours pour la fonction
          let found = func.roles.find((r) => r.name == role);

          // Rôle trouvé dans la fonction
          if (found) {
            // Rôle non hérité
            if (!found.herited) {
              item[func.name].hasRole = true;
            } else {
              // Rôle hérité
              item[func.name].isHerited = true;
            }
          }

          // Récupération du service lié à la fonction
          let service = this.services.find((s) => s.id == func.serviceId);
          if (service) {
            // Le service possède le rôle ??
            let foundServ = service.authorized.find((s) => s == role);

            if (foundServ) {
              // Rôle hérité par le service
              item[func.name].isHeritedByService = true;
            }
          }
          else {
            //console.log(`function ${JSON.stringify(func)}`);
          }
        }

        items.push(item);
      }

      return items;
    },

    /** Méthode de recherche d'un rôle */
    filterOnlyCapsText(value, search) {
      search = search.toUpperCase();

      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },

    /** Evènement sur le changement de sélection d'un service */
    onSelectedServiceChange() {
      this.selectedFunction = [];
    },

    /** Evènement sur le changement de sélection d'un service */
    onSelectedFunctionChange() {},

    /** Traite le click sur une checkbox
     * Reçois la ligne et la colonne
     * en fonction, attribu ou non l'héritage
     */
    onClickCheckBox(row, col) {
      this.changedRoles.push(row);
      this.changedHeaders.push(col);

      // Récupère la ligne dans la liste d'items
      let item = this.items.find((i) => i.name == row);

      // Récupère l'état du check de la cellule
      let check = item[col].hasRole;

      // récupère le rôle dans la liste originale
      let roleApm = this.rolesApmSource.find((r) => r.name == row);

      // Parcours la liste des rôles hérités
      for (let role of roleApm.grant) {
        // Récupère la row héritée
        let it = this.items.find((i) => i.name == role);
        // La valeur d'héritage est mise à la même valeur que celle du parent
        it[col].isHerited = check;

        // Si le rôle hérité était déjà check, on met la valeur à faux.
        if (check) {
          it[col].hasRole = false;
        }
      }
    },

    /** Annule le mode édition et remet les modifications dans l'état initial */
    clickOnCancelEdit() {
      if (this.hasChanged) {
        this.showAlertQuit = true;
        this.nextAlertQuit = function (value) {
          // la valeur est à undefined quand on veut quitter sans enregistrer
          // la valeur est à false qun on veut annuler la sortie
          if (value == undefined) {
            this.modeEdition = false;
            this.items = JSON.parse(JSON.stringify(this.itemsSource));            
            this.changedRoles = [];
            this.changedHeaders = [];
          }
        };
      } else {
        this.modeEdition = false;        
        this.changedRoles = [];
        this.changedHeaders = [];
      }
    },

    /** Evènement sur le click du bouton crayon qui fait passer la vue en édition */
    clickOnModeEdition() {
      this.modeEdition = true;
    },
  },
  computed: {
    // Fournit la liste des fonctions suivant le service sélectionné
    availableFunction() {
      if (this.selectedService == null) {
        return this.functions;
      } else {
        let ids = this.functions.filter(
          (f) => f.serviceId == this.selectedService.id
        );
        return ids;
      }
    },

    /** Construit la liste des colonnes de la matrice en fonction de :
     * - si un service est sélectionné
     * - si une ou plusieurs fonctions sont choisies
     * - si aucuns des 2 précédant --> affiche uniquement les colonnes qui ont un rôles attribuée
     */
    availableHeaders() {
      // La liste des colonnes de la table
      let headers = [];

      // La liste des fonctions a afficher
      let funcsHead = this.availableFunction;

      let head = [];

      // Si des fonctions sont sélectionnées, head contient le nom de toutes les fonctions sélectionnées
      if (this.selectedFunction.length > 0) {
        head = this.selectedFunction.map((f) => f.name);
      } else {
        // Aucune fonction sélectionnée mais un service oui
        if (this.selectedService) {
          head = funcsHead.map((f) => f.name);
        } else {
          // Aucune fonction sélectionnée, aucun service on affiche les fonction qui sont settées
          for (let func of funcsHead) {
            // Parcours des items
            for (let item of this.items) {
              // Item setté --> la fonction doit être affichée
              if (item[func.name] && item[func.name].hasRole) {
                head.push(func.name);
                break;
              }
            }
            if (!head.find((i) => i == func.name) && this.changedHeaders.find((i) => i == func.name)) {
              head.push(func.name);
            }
          }          
        }
      }

      // Ajout du header role
      headers.push({ text: "Roles", value: "name", width: "150px" });
      // Parcours des fonction à afficher  et construction en header
      for (let h of head) {
        let col = {};
        col.text = h;
        col.value = h.toLowerCase();
        col.align = "center";
        col.sortable = false;

        headers.push(col);
      }

      return headers;
    },

    /**
     * Construit la liste des rows de la matrice à afficher
     */
    availableItems() {
      let items = [];

      try {
        let headers = this.availableHeaders;

        let map = new Map();

        for (let func of this.functions) {
          map.set(func.name, func);
        }

        for (let role of this.items) {
          //le rôle est utilisé pour au moins une fonction
          let usedRole = false;
          let changedRole = false;

          //pour chaque fonction
          for (let h of headers) {
            if (h.value == "name") {
              continue;
            } else {
              // L'association role / f onction est-il check ?
              if (
                role[h.value] &&
                (role[h.value].hasRole ||
                  role[h.value].isHerited ||
                  role[h.value].isHeritedByService)
              ) {
                usedRole = true;
                break;
              }
            }
          }

          if (this.changedRoles.find((i) => i == role.name)) {
            changedRole = true;
          }

          // Ajout uniquement si set tous les rôle view ou si le role à au moin un set.
          let add = this.viewAllRole || usedRole || changedRole;
          if (add) {
            items.push(role);
          }
        }
      } catch (error) {
        console.error(error);
        items = [];
      }

      return items;
    },

    /** retourne vrai si un des items à chnagé par rapport à sa source */
    hasChanged() {
      let changed = false;

      // Le tableau source est null --> le modèle ne peut avoir changé
      if (!this.itemsSource) return false;

      let find = this.findFonctionHasChanged();
      if (find.length > 0) {
        changed = true;
      }

      return changed;
    },
  },
  mounted() {
    // Configure les éléments si on peut éditer les rôles
    this.canEditRole = hasRoles(this.roleForEdition);

    // Instanciation de la classe service
    this.serviceAcs = new ServiceAcs(this.$api.getAcsApi());
    this.serviceAuthAdminBackend = new AuthAdminService(
      this.$api.getAuthAdminApi()
    );
    //on affiche le bouton retour
    this.showBackButton = true;

    this.addStepForWorkflow("Choisir un périmètre");
    this.addStepForWorkflow("Consulter la configuration");
    this.nextStepForWorkflow();

    this.load();
  },
};
</script>
